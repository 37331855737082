export const SERVICE_OPTION_ALL = { name: 'all', value: 'All' } as const

export const SERVICE_OPTIONS = [
  { name: 'doctor', value: 'Doctors' },
  { name: 'hospital', value: 'Hospitals' },
  { name: 'pharmacy', value: 'Pharmacies' },
  SERVICE_OPTION_ALL,
] as const

export const OPENING_HOURS_OPTIONS = [
  { name: 'showAll', value: 'Show all' },
  { name: 'afternoon', value: 'Afternoon' },
  { name: 'noon', value: 'Noon' },
  { name: 'allDay', value: 'All day' },
] as const

export const SORT_OPTIONS = [
  { name: 'nearest', value: 'Nearest' },
  { name: 'az', value: 'A-Z' },
  { name: 'za', value: 'Z-A' },
] as const
