import styled from '@emotion/styled'
import { theme } from 'antd'

export const Wrapper = styled.div<{}>((props) => {
  const { token } = theme.useToken()
  return `
  
  padding: 0 60px;
  box-shadow: inset 0 0 35px #00417A12;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 3em;
  @media only screen and (max-width: ${token.screenMDMin}px) {
    padding: 0 20px;
  }
  `
})
export const Container = styled.div<{}>(`
  max-width: 1240px;
  `)
export const Header = styled.h1<{}>((props) => {
  const { token } = theme.useToken()
  return `
  color: var(--primaryColor);
  font-weight: 700;
  font-size: 44px;
  line-height: 50px;
  @media only screen and (max-width: ${token.screenMDMin}px) {
    font-size: 24px;
    line-height: 25px;
  }
  `
})
export const PageDescription = styled.p<{}>((props) => {
  const { token } = theme.useToken()
  return `
  font-size: 20px;
  line-height: 30px;
  @media only screen and (max-width: ${token.screenMDMin}px) {
    font-size: 16px;
    line-height: 24px;
  }
  `
})
