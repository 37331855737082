import { useState } from 'react'
import { useGooglePlacesApi } from './useGooglePlacesApi'
import { upperCaseFirstLetter } from '../utils/upperCaseFirstLetter'
import { PlaceResult } from '../types/googlePlacesTypes'
import { ResultListProp, SearchProps, Services } from '../types'
// import { useDoctorSearch } from './useDoctorSearch'
// import { GetMappedSpecializationKey } from '../utils/getMappedSpecializationKey'

export const useProviderSearch = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState<ResultListProp[]>()

  const { nearBySearch, isLoaded: googleIsLoaded } = useGooglePlacesApi()

  // const { search: doctorSearch } = useDoctorSearch()
  // const mapIqviaResponse = (response: { activities: { edges: any[] } }) => {
  //   return response?.activities?.edges.map((edge) => {
  //     const { individual } = edge.node
  //     const { address } = edge.node.workplace

  //     return {
  //       id: edge.node.id,
  //       name: `${individual.firstName} ${individual.lastName}`,
  //       specialization: individual.professionalType.label,
  //       openingHours: address.openHours,
  //       address: `${address.longLabel}, ${address.postalCode} ${address.county.label}`,
  //       phone: address.intlPhone,
  //       location: { lat: address.location.lat, lng: address.location.lon },
  //     }
  //   })
  // }
  const mapGoogleResponse = (response: PlaceResult[], institutionType: string, service: Services) => {
    return response.map((details: PlaceResult, index: number) => ({
      id: details.id || `${details.place_id}-${index}`,
      name: details.name,
      service,
      specialization: institutionType,
      address: details.vicinity,
      phone: details.formatted_phone_number || 'N/A',
      openNow: details.opening_hours?.isOpen() ? 'Open Now' : 'Closed',
      openingHours: details.openingHours?.weekday_text || ['No hours available'],
      periods: details.openingHours?.periods || [],
      location: {
        lat: details.geometry.location.lat(),
        lng: details.geometry.location.lng(),
      },
      place_id: details.place_id,
    }))
  }

  const search = async (props: SearchProps) => {
    const requests = []
    const { location } = props

    setIsLoading(true)

    const isIqviaEnabled = false

    const { specializations } = props
    if (isIqviaEnabled && props.specializations) {
      // const specialties = specializations && GetMappedSpecializationKey(specializations)
      // requests.push(
      //   doctorSearch({
      //     first: 20,
      //     offset: 0,
      //     ...(location && {
      //       country: location.country,
      //       currentPosition: location.coordinates,
      //     }),
      //     ...(specialties && {
      //       specialties: specialties
      //         .filter((specialty: IqviaSpecialty | null) => specialty !== null)
      //         .map((specialty: IqviaSpecialty) => specialty.id),
      //     }),
      //   }).then((res) => mapIqviaResponse(res)),
      // )
    }

    if (location?.coordinates) {
      for (const institutionType of props.serviceTypes) {
        if (institutionType.name === 'doctor' && specializations && specializations.length > 0) {
          for (const specialization of specializations) {
            try {
              const result = await nearBySearch({
                location: location.coordinates,
                radius: 5000,
                type: 'doctor',
                keyword: specialization,
              })

              requests.push(
                Promise.resolve(
                  mapGoogleResponse(
                    result as PlaceResult[],
                    upperCaseFirstLetter(specialization),
                    institutionType.name,
                  ),
                ),
              )
              // avoid exceeding quota:
              await new Promise((resolve) => setTimeout(resolve, 100))
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(`Error searching for ${specialization}:`, error)
            }
          }
        } else {
          try {
            const result = await nearBySearch({
              location: location.coordinates,
              radius: 5000,
              type: institutionType.name,
            })

            requests.push(
              Promise.resolve(
                mapGoogleResponse(
                  result as PlaceResult[],
                  upperCaseFirstLetter(institutionType.name),
                  institutionType.name,
                ),
              ),
            )
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Error searching for ${institutionType.name}:`, error)
          }
        }
      }
    }

    const responses = await Promise.all(requests)

    setIsLoading(false)

    setResults(responses.flat() as ResultListProp[])
  }

  return { search, isLoading, results, googleIsLoaded }
}
