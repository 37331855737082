import styled from '@emotion/styled'
import { Button as AntButton } from 'antd'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

export const Button = styled(AntButton, {
  shouldForwardProp: (prop) => prop !== 'transparent' && prop !== 'iconStrokeColor',
})<{ icon?: React.ReactNode; iconStrokeColor?: string; transparent?: boolean }>((props) => {
  return `
  
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  gap: 0.8em;
  border-radius: 18px;
  padding: 30px 18px;
  background-color: ${CSS_VARS.primaryColor};
  color: white;
  justify-content: center;
  text-decoration: none;
  ${props.icon ? `padding: 30px 18px;` : `padding: 20px 18px;`}
  ${props.iconStrokeColor ? `svg path { stroke: ${props.iconStrokeColor};}` : ''}
  ${
    props.transparent
      ? `
    background-color: transparent;
    color: black;
    `
      : ``
  }
`
})
export const ButtonWrapper = styled.div<{ background?: string }>(
  `
  margin: 2em -15px;
  
`,
)
