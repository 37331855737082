import { Button, Card as CardOriginal, Row, Typography } from 'antd'
import { CardProps as CardPropsOriginal } from 'antd/es/card'
import styled from '@emotion/styled'

import { CustomIcon } from '../CustomIcon/CustomIcon'
import waveTop from '../../assets/images/wave_top.svg'
import { CSS_VARS, JS_STYLING } from '../../resources/cssVariableConfig'
import React from 'react'

export interface CardProps extends CardPropsOriginal {
  needsExtraMargin: boolean
  useCompactBreakpoints: boolean
}

const Card = (props: CardProps) => {
  const { needsExtraMargin, useCompactBreakpoints, ...otherProps } = props
  return <CardOriginal {...otherProps} />
}

const { Title } = Typography

// TODO resolve !important issues: https://github.com/ant-design/ant-design/discussions/38753

export const WaveContainer = styled.div`
  > div {
    background-color: ${CSS_VARS.waveBgColor};
  }

  ::after {
    content: '';
    width: 100%;
    height: 84px;
    mask-image: url('${waveTop}');
    background-color: ${CSS_VARS.primaryColor};
    mask-size: cover;
    mask-position: bottom;
    display: block;
  }
`

export const StyledRow = styled(Row)`
  flex: 1;
  min-height: auto;
  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    margin: 0 16px;
  }
`

export const Header = styled(Title)`
  text-align: center;
  margin-top: 80px;
  font-size: clamp(30px, 5vw, 60px) !important; // 5vw on XL min breakpoint (=1200px width) equals to 60px
  font-family: ${CSS_VARS.fontFamily};
  font-weight: 700 !important;
  white-space: pre-line;

  color: ${CSS_VARS.primaryColor} !important;
`

export const SubHeader = styled(Title)`
  color: ${CSS_VARS.startScreenHeaderTextColor} !important;
  text-align: center;
  margin: 30px 0 140px 0 !important;
  font-size: 14px !important;
  white-space: pre-line;

  font-family: ${CSS_VARS.fontFamily};
  font-style: ${CSS_VARS.fontStyle};
  font-weight: ${CSS_VARS.fontWeight} !important;
`

export const SearchHeader = styled(Title)`
  text-align: center;
  margin: 16px 0 30px 0 !important;
  font-size: clamp(22px, 3.33vw, 40px) !important; // 3.33vw on XL min breakpoint (=1200px width) equals to ~40px
  font-family: 'Nunito', sans-serif;
  font-weight: 700 !important;
  font-family: ${CSS_VARS.fontFamily};
  color: ${CSS_VARS.primaryColor} !important;
`

export const Module = styled(Card)<{ needsExtraMargin: boolean; useCompactBreakpoints: boolean }>(
  `
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: space-between;

  box-shadow: 0px 0px 35px 0px rgba(0, 65, 122, 0.07) !important;
  border-radius: 15px;

  .ant-card-head {
    border-bottom-style: none;
    padding: 27.4px 30px 0;

    .ant-card-head-title {
      font-size: 22px;
      font-weight: 700 !important;

      margin-right: 80px;
      overflow: visible;
      white-space: normal;
      text-overflow: clip;
      color: ${CSS_VARS.primaryColor};
      font-family: ${CSS_VARS.fontFamily};
    }
  }

  .ant-card-body {
    color: ${CSS_VARS.lightTextColor};
    padding: 0 30px;
    margin: 7px 0 45px 0;

    font-family: ${CSS_VARS.fontFamily};
    font-size: ${CSS_VARS.fontSize};
    font-style: ${CSS_VARS.fontStyle};
    font-weight: ${CSS_VARS.fontWeight};
  }

  .ant-card-actions {
    border-top-style: none;
    padding: 0 30px 40.5px;
    border-radius: 15px;

    li {
      width: auto !important;
    }

    button {
      border-radius: 13px;
      min-width: 226px;

      background-color: ${CSS_VARS.primaryColor} !important;

      span {
        font-family: ${CSS_VARS.fontFamily};

        font-weight: ${CSS_VARS.buttonWeight};
        color: ${CSS_VARS.filledButtonFontColor};
      }
    }
  }
  `,
  (props) =>
    props.needsExtraMargin &&
    `
    @media screen and (max-width: ${props.useCompactBreakpoints ? '991px' : '767px'}) {
      margin-top: 55px;
    }
    `,
)

export const StyledCustomIcon = styled(CustomIcon)`
  position: absolute;
  top: -39px;
  right: 30px;
`

export const IconBackground = styled.div`
  position: absolute;
  top: -28px;
  right: 35px;
  width: 67px;
  height: 67px;
  background-color: white;
  border-radius: 34px;
`

export const CtaButton = styled(Button)`
  background-color: ${CSS_VARS.primaryColor} !important;
`
