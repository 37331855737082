import styled from '@emotion/styled'
import { theme } from 'antd'

export const Card = styled('div')<{ grid?: string }>((props) => {
  const { token } = theme.useToken()
  return `
  border-radius: 10px;
  padding: 20px;
  
  gap: 16px;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  ${
    props.grid
      ? `
    display: grid;
    grid-template-columns: ${props.grid};
    align-items: center;
    gap: 40px;
    @media only screen and (max-width: ${token.screenMDMin}px) {
      grid-template-columns: 1fr;
    }
    `
      : `
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `
  }
  
  `
})

export const CardItem = styled('div')<{ justify?: string; disable?: boolean }>((props) => {
  return `
  
  display: flex;
  

  
  border-left: 1px solid rgb(0,0,0,0.1);
  margin-left: -20px;
  padding-left: 20px;

  :first-child,:last-child{ border: none }

  h4{
    color: #808080;
    font-size: 14px;
  }
  
  ${props.justify ? `justify-content: ${props.justify};` : ''}
  ${props.disable ? `opacity: 0.4; pointer-events: none;` : ''}
  `
})
export const CardAddress = styled('div')((props) => {
  return `
  font-size: 16px;
  font-weight: 700;
  margin: 4px 0 18px 0;
  `
})
export const CardDetails = styled('div')((props) => {
  return `
  font-size: 16px;
  display: flex;
  gap: 8px;
  width: 100%;
  > div {
   width:100%;
  }
  `
})
export const CardOpeningHours = styled('div')((props: { open: boolean }) => {
  return `
  font-size: 16px;
  display: flex;
  gap: 8px;
  width: 100%;
  > p {
    width: 100%; 
    margin-bottom:0;
    span {
      display: block;
      margin-bottom: 10px;
    }
    span:last-child {
      margin-bottom: 24px;
    }
  }
  svg {
    width: 12px;
    height: 12px;
    margin-top:5px;
    ${
      props.open &&
      `
      transform: rotate(180deg);
      `
    }
  }
  
  button{
    margin-top: -4px!important;
  }
  `
})
export const CardHeader = styled('div')((props) => {
  return `
  display: flex;
  gap: 16px;
  h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: var(--primaryColor);
    margin: 0;
  }
  p{
    font-size: 14px;
    line-height: 20px;
    color: #808080;
    margin: 0;
  }
  > div{
    display:block;
  }
  `
})
export const CardWrapper = styled('div')((props) => {
  return `
  margin: 1em 0;
  `
})
