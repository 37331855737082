import React, { FC } from 'react'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './Impressum.module.less'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { ModalText } from '../ModalText/ModalText'
import { useI18n } from '../../../hooks'
import { Imprint } from '../../../models'

interface Props {
  scIcImprint: Imprint | null
}

export const ImpressumContactData: FC<Props> = (props) => {
  const { scIcImprint } = props
  const { i18n } = useI18n()

  return (
    <div>
      {scIcImprint && (
        <>
          <h2 style={{ color: CSS_VARS.DARK_BLUE_COLOR, fontFamily: CSS_VARS.fontFamily }}>
            {i18n('xund.imprint.contact')}
          </h2>

          <div className={styles.impressumItemWithIcon}>
            <CustomIcon icon="https" className={styles.impressumIcon} />

            <a
              href={scIcImprint.impressum.companyWebsite.path}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#1890ff', fontSize: CSS_VARS.fontSize }}
            >
              {scIcImprint.impressum.companyWebsite.path}
            </a>
          </div>

          <div className={styles.impressumItemWithIcon}>
            <CustomIcon icon="envelope" className={styles.impressumIcon} />

            <ModalText title={scIcImprint.impressum.contactEmail.path} style={{ color: 'black' }} />
          </div>

          <div className={styles.impressumItemWithIcon}>
            <CustomIcon icon="phone" className={styles.impressumIcon} />

            <ModalText title={scIcImprint.impressum.bugReportEmail.path} style={{ color: 'black' }} />
          </div>

          <div className={styles.impressumItemWithIcon}>
            <CustomIcon icon="address" className={styles.impressumIcon} />

            <ModalText title={scIcImprint.impressum.contactAddress} style={{ color: 'black' }} />
          </div>
        </>
      )}
    </div>
  )
}
