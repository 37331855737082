import { createContext, useContext } from 'react'
import { z } from 'zod'
import { MAX_AGE, MAX_HEIGHT, MAX_WEIGHT, MIN_AGE, MIN_HEIGHT, MIN_WEIGHT } from 'health-check-api'

export const CHECK_TYPE_ILLNESS_CHECK = 'ILLNESS_CHECK'
export const CHECK_TYPE_SYMPTOM_CHECK = 'SYMPTOM_CHECK'

export const zBirth = z.coerce.date()

export const zGender = z.enum(['male', 'female'])
export type Gender = z.infer<typeof zGender>

export const zCheckId = z.string().uuid()

export type ContinueCheckStatus = 'INVALID_ID' | 'CHECK_ENDED' | 'CHECK_CLOSED'

export const zCheckType = z.enum([CHECK_TYPE_ILLNESS_CHECK, CHECK_TYPE_SYMPTOM_CHECK])

export const zState = z.string().uuid()

export const zHeight = z.number().min(MIN_HEIGHT).max(MAX_HEIGHT)

export const zWeight = z.number().min(MIN_WEIGHT).max(MAX_WEIGHT)

export const zAge = z.number().min(MIN_AGE).max(MAX_AGE)

const initialValue: {
  isServicesOpen: boolean
  setServicesOpen: (value: boolean) => void
  isSideMenuOpen: boolean
  setSideMenuOpen: (value: boolean) => void
  isContentLibraryOpen: boolean
  setContentLibraryOpen: (value: boolean) => void
  initialGender: Gender | ''
  setInitialGender: (value: Gender | '') => void
  initialBirth: string
  setInitialBirth: (value: string) => void
  initialHeight: number | undefined
  setInitialHeight: (value: number) => void
  initialWeight: number | undefined
  setInitialWeight: (value: number) => void
  continueCheckStatus: ContinueCheckStatus | ''
  setContinueCheckStatus: (value: ContinueCheckStatus | '') => void
  isCheckOnlyMode: boolean
  setCheckOnlyMode: (value: boolean) => void
  isRestartDisabled: boolean
  setRestartDisabled: (value: boolean) => void
  initialAge: number | undefined
  setInitialAge: (value: number) => void
  isPreventNavigationHookEnabled: boolean
  setPreventNavigationHookEnabled: (value: boolean) => void
} = {
  isServicesOpen: false,
  setServicesOpen: () => '',
  isSideMenuOpen: false,
  setSideMenuOpen: () => '',
  isContentLibraryOpen: false,
  setContentLibraryOpen: () => '',
  initialGender: '',
  setInitialGender: () => '',
  initialBirth: '',
  setInitialBirth: () => '',
  continueCheckStatus: '',
  setContinueCheckStatus: () => '',
  isCheckOnlyMode: false,
  setCheckOnlyMode: () => '',
  isRestartDisabled: false,
  setRestartDisabled: () => '',
  initialHeight: undefined,
  setInitialHeight: () => null,
  initialWeight: undefined,
  setInitialWeight: () => null,
  initialAge: undefined,
  setInitialAge: () => null,
  isPreventNavigationHookEnabled: true,
  setPreventNavigationHookEnabled: () => null,
}

export const AppStateContext = createContext(initialValue)

/**
 * The custom hook for the app state context
 *
 * @returns The custom hook for the app state context
 */
export const useAppStateContext = () => useContext(AppStateContext)
