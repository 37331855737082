import { useState } from 'react'
import { useApiGatewayContext } from '../context'
import { triggerBrowserDownload } from '../utils/triggerBrowserDownload'
import { useQuestionContext } from '../context/QuestionContext'

export const useDownloadReportAsPDF = () => {
  const { apiGateway } = useApiGatewayContext()

  const [isDownloading, setIsDownloading] = useState(false)

  const { checkType } = useQuestionContext()

  const download = async () => {
    setIsDownloading(true)

    const { data } = await apiGateway.get<Blob>('v1/chatbot/report/pdf', { responseType: 'blob' })
    const date = new Date().toISOString()

    triggerBrowserDownload(data, `${checkType.toLocaleLowerCase()}_report_${date}.pdf`)

    setIsDownloading(false)
  }
  return { download, isDownloading }
}
