import React, { FC, useMemo } from 'react'
import { Col, Row } from 'antd'

import { CheckType } from '../../models'
import { useApiKeyScopes, useI18n, useLanguage } from '../../hooks'
import { ContentLibrarySearch } from './ContentLibrarySearch/ContentLibrarySearch'
import {
  CtaButton,
  Header,
  IconBackground,
  Module,
  SearchHeader,
  StyledCustomIcon,
  StyledRow,
  SubHeader,
  WaveContainer,
} from './StartScreen.styled'
import { CHECK_TYPE_ILLNESS_CHECK, CHECK_TYPE_SYMPTOM_CHECK } from '../../context'

/**
 * @param props The props object
 * @param props.showTermsAndConditions The method for showing the terms and conditions
 * @returns The StartScreen component
 */
export const StartScreen: FC<{
  showTermsAndConditions: (param: CheckType) => void
}> = ({ showTermsAndConditions }) => {
  const { currentLanguage } = useLanguage()
  const { hasApiKeyScope } = useApiKeyScopes()
  const { i18n, getCustomTranslationWithLocalFallback } = useI18n()

  const useCompactBreakpoints = ['fr', 'fr-formal'].includes(currentLanguage)

  const isMedicalResearchMode = window.xundEnvironment.APP_MODE === 'MEDICAL_RESEARCH'

  const scrollToTop = () => document.body?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  const cards = useMemo(() => {
    return [
      ...(hasApiKeyScope('execute_symptom_checks')
        ? [
            {
              key: 'symptom_check',
              title: i18n('xund.start.symptomCheckHeader'),
              description: i18n('xund.start.symptomCheckDescription'),
              ctaText: i18n('xund.start.startSymptomCheck'),
              ctaOnClick: () => {
                showTermsAndConditions(CHECK_TYPE_SYMPTOM_CHECK)
                scrollToTop()
              },
              icon: 'scIcon',
            },
          ]
        : []),
      ...(hasApiKeyScope('execute_illness_checks')
        ? [
            {
              key: 'illness_check',
              title: i18n('xund.start.illnessCheckHeader'),
              description: i18n('xund.start.illnessCheckDescription'),
              ctaText: i18n('xund.start.startIllnessCheck'),
              ctaOnClick: () => {
                showTermsAndConditions(CHECK_TYPE_ILLNESS_CHECK)
                scrollToTop()
              },
              icon: 'icIcon',
            },
          ]
        : []),
    ]
  }, [hasApiKeyScope, i18n, showTermsAndConditions])

  const computedBreakpoints = useMemo(() => {
    const normalBreakpoints = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }
    const compactBreakpoints = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }

    return useCompactBreakpoints ? compactBreakpoints : normalBreakpoints
  }, [useCompactBreakpoints])

  return (
    <>
      <WaveContainer>
        <div>
          <Row justify="center" style={{ flex: 1 }}>
            <Col span={19}>
              <StyledRow>
                <Col span={24}>
                  <Header>{getCustomTranslationWithLocalFallback('welcomeGreeting')}</Header>
                  <SubHeader level={5}>{getCustomTranslationWithLocalFallback('welcomeMessage')}</SubHeader>
                </Col>
              </StyledRow>

              <Row
                gutter={[0, 55]}
                style={isMedicalResearchMode ? { flex: 1 } : undefined}
                justify={isMedicalResearchMode ? 'center' : undefined}
              >
                {cards.map((card, i) => {
                  return (
                    <Col {...computedBreakpoints} key={card.key}>
                      <Module
                        title={card.title}
                        bordered={false}
                        actions={[
                          <CtaButton type="primary" shape="round" key="cta" onClick={card.ctaOnClick}>
                            {card.ctaText}
                          </CtaButton>,
                        ]}
                        needsExtraMargin={false} //TODO remove if unnecessary
                        useCompactBreakpoints={useCompactBreakpoints}
                      >
                        <IconBackground />
                        <StyledCustomIcon icon={card.icon} />

                        {card.description}
                      </Module>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </WaveContainer>
      {hasApiKeyScope('medical_library') && (
        <StyledRow justify="center">
          <Col xs={19} sm={19} md={19} lg={12} xl={12}>
            <Row>
              <Col span={24}>
                <SearchHeader level={3}>{i18n('xund.start.searchHeader')}</SearchHeader>
                <ContentLibrarySearch showTermsAndConditions={showTermsAndConditions} />
              </Col>
            </Row>
          </Col>
        </StyledRow>
      )}
    </>
  )
}
