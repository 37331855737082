import styled from '@emotion/styled'
import { Select as AntSelect } from 'antd'
import { CustomSelectProps } from '../types'

export const Select = styled(AntSelect)((props: CustomSelectProps) => {
  return `
  .ant-select-selector{
    padding: 0;
  }
  .ant-select-selection-placeholder{
    inset-inline-start: 0;
  }
  .ant-select-selection-search{
    margin-inline-start: 0px;
  }
  .ant-select-arrow {
    display: none !important;
  }
  .ant-select-selector{
  
  }
  
  ${
    props.prefix &&
    `
    .ant-select-selection-item {font-weight: 700;}
  `
  }
`
})
export const Container = styled.div((props) => {
  return `
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  .anticon-down {
    position: absolute;
    
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    svg{
      width: 8px;
      height: 8px;
    }
  }
    .ant-select-single{
    margin-left: -11px;
  }
`
})
export const Prefix = styled.div((props) => {
  return `
  white-space: nowrap;
`
})
