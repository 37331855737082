import styled from '@emotion/styled'
import { theme, Pagination as AntPagination } from 'antd'

export const Results = styled.div<{ variant?: 'empty' | 'initial' | 'valid' }>((props) => {
  const { token } = theme.useToken()
  return `
    margin: 5em 0 2em 0;
    h2 {
      font-size: 30px;
      color: var(--primaryColor);
      font-weight: 700;
      margin: 1.5em 0;
    }
      
    ${
      props.variant === 'valid'
        ? `.hpc-result-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 32px;
      align-items: start;
      @media only screen and (max-width: ${token.screenMDMin}px) {
        grid-template-columns: 1fr;
      }
    }
  `
        : ''
    }
  `
})

export const EmptyPlaceholder = styled.div<{}>((props) => {
  return `
  margin: 3em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin:1em 0 2.5em 0;
  }
  h2{
    font-size: 30px;
    color: var(--primaryColor);
  }
  p{
    max-width: 800px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }
  `
})
export const Pagination = styled(AntPagination)((props) => {
  return `
  padding: 5em 0;
  text-align: center;
  li.ant-pagination-item,li.ant-pagination-next,li.ant-pagination-prev {
    border-radius: 50%;
    border: none;
    min-width: 25px;
    height: 25px;
    line-height: 25px;
  }
  li.ant-pagination-next,li.ant-pagination-prev {
    .ant-pagination-item-link{
      border-radius: 50%;
      span{
        vertical-align: 0;
      }
      svg{
        height: 9px
      }
    }
  }
  li.ant-pagination-item.ant-pagination-item-active {
    
    background-color: var(--primaryColor);
    a {
      color: white;
    }
  }
  `
})
