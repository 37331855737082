import { MedicalEngineIllnessCheckReport, MedicalEngineSymptomCheckReport } from 'medical-engine-api'
import { Col, Row } from 'antd'
import React, { CSSProperties, FC, lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { flushSync } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { useEffectOnce, useElementSize } from 'usehooks-ts'
import { useLocation } from 'react-router'
import dayjs from 'dayjs'
import { AxiosError } from 'axios'
import {
  CHECK_TYPE_ILLNESS_CHECK,
  CHECK_TYPE_SYMPTOM_CHECK,
  useApiGatewayContext,
  useAppStateContext,
  useWebAppConfigContext,
  zAge,
  zBirth,
  zCheckId,
  zCheckType,
  zGender,
  zHeight,
  zState,
  zWeight,
} from '../../context'
import { useI18n, useIsUserOnline, useParentEvents, useScreenSize } from '../../hooks'
import {
  ChatbotQuestionAnswer,
  CheckPhaseStep,
  CheckType,
  OverviewResponse,
  Question,
  ResponseType,
  ResponseTypeWithoutReport,
  WebAppConfig,
} from '../../models'
import {
  legalDisclaimer,
  loading,
  patientIntentBeforeCheck,
  questionDetailedTypesGoToStartOnBackButton,
  questionIdsWithoutAnswerSaving,
  startQuestion,
} from '../../resources/startingQuestion'
import { changeCheckStatus, determineBackgroundImageToUse } from '../../utils'
import { ContentLibrary } from '../ContentLibrary/ContentLibrary'
import { ContentLibraryTitle } from '../ContentLibrary/ContentLibraryTitle/ContentLibraryTitle'
import { Footer } from '../Footer/Footer'
import { NoConnection } from '../NoConnection/NoConnection'
import { SideMenu } from '../SideMenu/SideMenu'
import { PoweredByXund } from '../General/PoweredByXund/PoweredByXund'
import { useQuestionContext } from '../../context/QuestionContext'
import { StartScreen } from '../StartScreen/StartScreen'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { ProgressDetailedData } from '../../models/api/response/detailedTypeData/ProgressDetailedData'
import styles from './MainPage.module.less'
import {
  actualWebAppConfig,
  CheckClosedError,
  CheckFinishedError,
  getInitializeIllnessheckPayload,
  getInitializeSymptomCheckPayload,
  Payload,
} from './MainPage.utils'
import { PageContainer, QuestionHandlerContainer } from './MainPage.styled'
import { StatusEndpointResponse } from '../../models/api/response/StatusEndpointResponse'
import { BaseModal } from '../Modals/BaseModal/BaseModal'
import { ModalText } from '../Modals/ModalText/ModalText'
import { isContinueCheck } from '../../utils/envHelpers'
import { Header } from '../Header/Header'
import { useMatomo } from '@datapunt/matomo-tracker-react'

import { LoadingIndicator as LegacyLoadingIndicator } from '../../common/Indicators/LoadingIndicator'
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator'
import { FooterReportRedesign } from '../Footer/FooterReportRedesign'
import { useStaticFeatureFlag } from 'xund-configcat-react'
import { HealthCareProvider } from '../HealthCareProvider/HealthCareProvider'

const QuestionHandler = lazy(() =>
  import('./QuestionHandler/QuestionHandler')
    .then((module) => ({ default: module.QuestionHandler }))
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(`Error loading`, err)
      return { default: () => null }
    }),
)

const Services = lazy(() =>
  import('../Services/Services')
    .then((module) => ({ default: module.Services }))
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(`Error loading`, err)
      return { default: () => null }
    }),
)

/**
 * The main page component
 *
 * @param props The props object
 * @param props.testWebAppConfig set config as parameter only use it in tests!
 * @returns The main page component
 */
export const MainPage: FC<{
  testWebAppConfig?: WebAppConfig
}> = ({ testWebAppConfig }) => {
  const [chatbotQuestionData, setChatbotQuestionData] = useState<ResponseType[]>([startQuestion])
  const [checkIfAddMoreSymptomId, setCheckIfAddMoreSymptomId] = useState('')
  const [checkPhaseStep, setCheckPhaseStep] = useState<CheckPhaseStep>(0)
  const [isOverview, setIsOverview] = useState(false)
  const [isWarningSign, setIsWarningSign] = useState(false)
  const [isReportMissing, setIsReportMissing] = useState(false)
  const [showReportActions, setShowReportActions] = useState(false)
  const [contentLibraryItemId, setContentLibraryItemId] = useState('')
  const [error, setError] = useState<Error>()
  const [continueCheck, setContinueCheck] = useState(false)

  const { isMobileView } = useScreenSize()
  const { apiGateway, checkId, setCheckId, initialSymptom, initialIllness, setInitialIllness } = useApiGatewayContext()
  const isMedicalResearchMode = window.xundEnvironment.APP_MODE === 'MEDICAL_RESEARCH'
  const isWarningSignsPopupEnabled = window.xundEnvironment.WARNING_SIGNS_POPUP_ENABLED === 'true'
  const { isUserOnline } = useIsUserOnline()
  const realWebAppConfig = useWebAppConfigContext()
  const { backgroundImage, boxShadowHidden, illnessCheckIcd10Code, skipLegalQuestions } = actualWebAppConfig(
    testWebAppConfig,
    realWebAppConfig.webAppConfig,
  )

  const { parentEvents } = useParentEvents()

  const {
    currentResponse,
    setCurrentResponse,
    isLoading,
    setIsLoading,
    isReporting,
    setIsReporting,
    isCheckFinished,
    setIsCheckFinished,
    checkType,
    setCheckType,
    setMainContainerWidth,
    setHideSkipButton,
    setNextButtonLogic,
    setNextButtonI18nKey,
    setIsNextButtonDisabled,
    setDirectCheck,
  } = useQuestionContext()

  const {
    isServicesOpen,
    setServicesOpen,
    setSideMenuOpen,
    isContentLibraryOpen,
    setContentLibraryOpen,
    initialBirth,
    setInitialBirth,
    initialGender,
    setInitialGender,
    setInitialHeight,
    setInitialWeight,
    setContinueCheckStatus,
    continueCheckStatus,
    isCheckOnlyMode,
    setCheckOnlyMode,
    setRestartDisabled,
    setInitialAge,
  } = useAppStateContext()

  const { i18n } = useI18n()

  const { trackPageView } = useMatomo()

  const [mainContainerRef, { width }] = useElementSize()

  const currentResponseDetailedType = useMemo(
    () => (currentResponse as ResponseTypeWithoutReport)?.detailedType,
    [currentResponse],
  )

  const { search: queryString } = useLocation()

  const { webAppConfig } = useWebAppConfigContext()

  useEffect(() => {
    // TODO test with config value
    const isDirectIllnessCheckMode = Boolean(illnessCheckIcd10Code)

    if (isDirectIllnessCheckMode) {
      setCheckOnlyMode(true)
    }

    if (skipLegalQuestions && checkType) {
      startCheck()
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    apiGateway,
    checkType,
    illnessCheckIcd10Code,
    isCheckOnlyMode,
    setCheckId,
    setCheckOnlyMode,
    setCheckType,
    setCurrentResponse,
    setIsLoading,
    skipLegalQuestions,
  ])

  useEffect(() => {
    ;(async () => {
      const searchParams = new URLSearchParams(queryString)

      const birthSearchParam = searchParams.get('birth') ?? '' // ?? '' is necessary because if query string param 'birth' is not provided, it's null value will be coerced to 1970-01-01 when testing without the wrapper
      const genderSearchParam = searchParams.get('gender')
      const heightSearchParam = searchParams.get('height')
      const weightSearchParam = searchParams.get('weight')
      const checkIdSearchParam = searchParams.get('checkId')
      const restartSearchParam = searchParams.get('restart') // TODO connect to auth wrapper?
      const stateSearchParam = searchParams.get('state')
      const directCheckSearchParam = searchParams.get('directCheck')
      const ageSearchParam = searchParams.get('age')

      const parsedBirth = zBirth.safeParse(birthSearchParam)

      const parsedAge = zAge.safeParse(parseInt(ageSearchParam ?? '', 10))

      const parsedDirectCheck = zCheckType.safeParse(directCheckSearchParam)

      const parsedHeight = zHeight.safeParse(parseFloat(heightSearchParam ?? ''))

      const parsedWeight = zWeight.safeParse(parseFloat(weightSearchParam ?? ''))

      if (parsedDirectCheck.success) {
        setDirectCheck(parsedDirectCheck.data)

        showTermsAndConditions(parsedDirectCheck.data)
      }

      if (parsedBirth.success) {
        const formattedBirth = dayjs(parsedBirth.data).format('YYYY-MM-DD')

        setInitialBirth(formattedBirth)
      }

      const parsedGender = zGender.safeParse(genderSearchParam)

      if (parsedGender.success) {
        setInitialGender(parsedGender.data)
      }
      if (parsedHeight.success) {
        setInitialHeight(parsedHeight.data)
      }

      if (parsedWeight.success) {
        setInitialWeight(parsedWeight.data)
      }

      if (parsedAge.success) {
        setInitialAge(parsedAge.data)
      }

      if (isContinueCheck()) {
        const parsedCheckId = zCheckId.safeParse(checkIdSearchParam)

        if (parsedCheckId.success) {
          const checkToContinue = parsedCheckId.data

          flushSync(() => {
            setCheckId(checkToContinue)
          })

          setContinueCheck(true)

          await apiGateway.interceptors.request.use(
            async (config) => {
              config.headers.set('check-id', checkToContinue)
              return config
            },
            (_error) => {
              return Promise.reject(_error)
            },
          )
        }

        const parsedRestart = zCheckType.safeParse(restartSearchParam)

        if (parsedRestart.success) {
          const checkToRestart = parsedRestart.data

          showTermsAndConditions(checkToRestart)
        }
      }

      const parsedState = zState.safeParse(stateSearchParam)

      if (parsedState.success) {
        setCheckOnlyMode(true)
        setRestartDisabled(true)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiGateway,
    // chatbotQuestionData, // TODO try resolving
    queryString,
    setCheckId,
    setCheckType,
    setCurrentResponse,
    setInitialBirth,
    setInitialGender,
  ])

  useEffect(() => {
    ;(async () => {
      if (!apiGateway) {
        return
      }

      if (!continueCheck) {
        return
      }

      if (!isContinueCheck()) {
        return
      }

      try {
        const { data } = await apiGateway.get<StatusEndpointResponse>('/v1/chatbot/status')

        if (data.checkType === 'ILLNESSCHECK') {
          setCheckType(CHECK_TYPE_ILLNESS_CHECK)
        } else if (data.checkType === 'SYMPTOMCHECK') {
          setCheckType(CHECK_TYPE_SYMPTOM_CHECK)
        }

        setCurrentResponse(legalDisclaimer)

        if (
          [
            'ILLNESS_CHECK_REPORT',
            'SYMPTOM_CHECK_REPORT',
            'AFTER_ILLNESS_CHECK_REPORT_ACTION_SELECTOR',
            'AFTER_SYMPTOM_CHECK_REPORT_ACTION_SELECTOR',
          ].includes(data.currentState.detailedState)
        ) {
          throw new CheckFinishedError()
        }

        if (data.isClosed) {
          throw new CheckClosedError()
        }

        const newChatbotQuestionData = [...chatbotQuestionData, legalDisclaimer, ...data.questions].slice(0, -1)

        setChatbotQuestionData(newChatbotQuestionData)
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.response?.status === 404) {
            setContinueCheckStatus('INVALID_ID')
          } else if (e.response?.status === 403) {
            setContinueCheckStatus('CHECK_CLOSED')
          }
        } else if (e instanceof CheckClosedError) {
          setContinueCheckStatus('CHECK_CLOSED')
        } else if (e instanceof CheckFinishedError) {
          setContinueCheckStatus('CHECK_ENDED')
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiGateway,
    // chatbotQuestionData, // TODO try resolving
    continueCheck,
    setCheckType,
    setContinueCheckStatus,
    setCurrentResponse,
  ])

  useEffectOnce(() => {
    trackPageView({})
  })

  /**
   * Starts the check
   */
  const startCheck = useCallback(async () => {
    try {
      setIsLoading(true)

      let checkIdToSet = ''

      let payload: Payload = initialSymptom
        ? getInitializeSymptomCheckPayload(initialSymptom)
        : initialIllness
        ? getInitializeIllnessheckPayload(initialIllness)
        : {}

      if (initialGender) {
        payload = {
          ...payload,
          patient: {
            ...payload.patient,
            resourceType: 'Patient',
            gender: initialGender,
          },
        }
      }

      if (initialBirth) {
        payload = {
          ...payload,
          patient: {
            ...payload.patient,
            resourceType: 'Patient',
            birthDate: initialBirth,
          },
        }
      }

      if (checkType === CHECK_TYPE_SYMPTOM_CHECK) {
        const { data } = await apiGateway.post<{ checkId: string }>(
          `v1/chatbot/initialize/symptomCheck`,
          isMedicalResearchMode ? {} : payload,
        )

        checkIdToSet = data.checkId
      } else if (checkType === CHECK_TYPE_ILLNESS_CHECK) {
        const { data } = await apiGateway.post<{ checkId: string }>(
          `v1/chatbot/initialize/${isMedicalResearchMode ? 'symptomCheck' : 'illnessCheck'}`,
          isMedicalResearchMode ? {} : payload,
        )

        checkIdToSet = data.checkId
      }

      flushSync(() => {
        setCheckId(checkIdToSet)
      })

      const showPatinetIntentBeforeCheck =
        (checkType === CHECK_TYPE_SYMPTOM_CHECK && webAppConfig.scReportPatientIntentBefore) ||
        (checkType === CHECK_TYPE_ILLNESS_CHECK && webAppConfig.icReportPatientIntentBefore)
      if (showPatinetIntentBeforeCheck) {
        setCurrentResponse(patientIntentBeforeCheck)
        setChatbotQuestionData([startQuestion, patientIntentBeforeCheck])
      } else {
        stepForwardInTheFlow()
      }
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [
    apiGateway,
    checkType,
    initialBirth,
    initialGender,
    initialIllness,
    initialSymptom,
    isMedicalResearchMode,
    setCheckId,
    setCurrentResponse,
    setIsLoading,
  ])

  const showTermsAndConditions = useCallback(
    async (checkTypeToStart: CheckType) => {
      setCheckType(checkTypeToStart)
      if (!skipLegalQuestions) {
        setCurrentResponse(legalDisclaimer)
        setChatbotQuestionData([startQuestion, legalDisclaimer])
      } else {
        setCurrentResponse(loading)
        setChatbotQuestionData([startQuestion, loading])
      }
    },
    [chatbotQuestionData, setCheckType, setCurrentResponse],
  )

  const getQuestion = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get<Question>('/v1/chatbot/question')

      if (data.detailedType === 'CHECK_IF_ADD_MORE_SYMPTOMS') {
        setCheckIfAddMoreSymptomId(data.id)
      }

      if (data.checkStateInfo.state === 'OVERVIEW' && continueCheck) {
        const { data: overview } = await apiGateway.get<OverviewResponse>('/v1/chatbot/overview')

        setCurrentResponse({ ...overview, id: checkIfAddMoreSymptomId })
        setChatbotQuestionData([{ ...overview, id: checkIfAddMoreSymptomId }])
      } else {
        setCurrentResponse(data)
        setChatbotQuestionData([...chatbotQuestionData, data])
      }

      changeCheckStatus(data?.checkStateInfo?.state, setCheckPhaseStep)
      setIsWarningSign(data.detailedType === 'EMERGENCY')
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway, chatbotQuestionData, checkIfAddMoreSymptomId, continueCheck, setCurrentResponse, setIsLoading])

  const stepForwardInTheFlow = () => {
    setCurrentResponse(null)
  }

  const stepBackInTheFlow = useCallback(async () => {
    const showStartScreen = () => {
      // resetting every value of QuestionContext to their initial value
      setHideSkipButton(false)
      setCurrentResponse(startQuestion)
      setNextButtonLogic(null)
      setNextButtonI18nKey('xund.general.confirm')
      setIsLoading(false)
      setIsNextButtonDisabled(false)
      setIsReporting(false)
      setIsCheckFinished(false)
      setCheckType('')
      setMainContainerWidth(0)
    }

    // TODO: simplify edge cases similarly
    const firstIsStartScreen = (chatbotQuestionData[0] as ResponseTypeWithoutReport).detailedType === 'START_SCREEN'
    const secondIsLoading = (chatbotQuestionData[1] as ResponseTypeWithoutReport).detailedType === 'LOADING'

    if (firstIsStartScreen && secondIsLoading && chatbotQuestionData.length === 3) {
      showStartScreen()

      return
    }

    // boolean variables to handle special cases when initial birth or gender is provided
    // and the back button has to handle that (by skipping questions backwards too)
    const response = currentResponse as ResponseTypeWithoutReport

    const isBirthProvidedInitially = Boolean(initialBirth) && response?.detailedType === 'GENDER'

    const isFirstProgressScreen =
      response?.detailedType === 'PROGRESS' &&
      ((response as Question).detailedTypeData as ProgressDetailedData).currentStep === 1
    const isIllnessSelectorScreen = response?.detailedType === 'ILLNESS_SELECTOR'
    const isBirthAndGenderProvidedInitially =
      Boolean(initialBirth) && Boolean(initialGender) && (isFirstProgressScreen || isIllnessSelectorScreen)

    if (questionDetailedTypesGoToStartOnBackButton.includes(currentResponseDetailedType)) {
      if (isCheckOnlyMode) {
        return
      }

      showStartScreen()
      return
    }

    if (isOverview) {
      setIsOverview(false)
    }

    const secondToLastQuestionIndex = 2

    if (
      !isBirthProvidedInitially &&
      !isBirthAndGenderProvidedInitially &&
      (((currentResponse as ResponseTypeWithoutReport)?.id &&
        (currentResponse as ResponseTypeWithoutReport).detailedType !== 'AGE') ||
        isOverview)
    ) {
      setIsLoading(true)
      try {
        await apiGateway.post('v1/chatbot/restoreToQuestion', {
          questionId: (
            chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex] as ResponseTypeWithoutReport
          )?.id,
        })

        await getQuestion()
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsLoading(false)
      }
    } else {
      setCurrentResponse(chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex])
    }

    setChatbotQuestionData(chatbotQuestionData.slice(0, chatbotQuestionData.length - 1))

    changeCheckStatus(
      (chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex] as ResponseTypeWithoutReport)
        ?.checkStateInfo?.state,
      setCheckPhaseStep,
    )
  }, [
    currentResponse,
    initialBirth,
    initialGender,
    currentResponseDetailedType,
    isOverview,
    chatbotQuestionData,
    isCheckOnlyMode,
    setHideSkipButton,
    setCurrentResponse,
    setNextButtonLogic,
    setNextButtonI18nKey,
    setIsLoading,
    setIsNextButtonDisabled,
    setIsReporting,
    setIsCheckFinished,
    setCheckType,
    setMainContainerWidth,
    apiGateway,
    getQuestion,
  ])

  const sendAnswer = useCallback(
    async (answer: ChatbotQuestionAnswer, isInfoQuestion?: boolean) => {
      if (!isLoading) {
        if (questionIdsWithoutAnswerSaving.includes(answer.questionId)) {
          stepForwardInTheFlow()
          return
        }

        try {
          if (!isInfoQuestion) {
            setIsLoading(true)
          }

          const { data } = await apiGateway.post<{
            checkStatus: 'FINISHED'
            mandatoryFunction: 'OVERVIEW' | 'REPORT'
          }>('/v1/chatbot/answer', answer)

          if (isInfoQuestion) {
            return
          }

          if (data?.mandatoryFunction === 'OVERVIEW' && data.checkStatus !== 'FINISHED') {
            setIsOverview(true)
          } else if (data?.mandatoryFunction === 'REPORT') {
            setIsReportMissing(true)
          } else if (data?.checkStatus === 'FINISHED') {
            setIsCheckFinished(true)
            return
          }

          const lastDataItemIndex = chatbotQuestionData.length - 1
          const lastDataItem = chatbotQuestionData[lastDataItemIndex]

          setChatbotQuestionData([
            ...chatbotQuestionData.slice(0, lastDataItemIndex),
            {
              ...lastDataItem,
              answer,
            } as Question,
          ])

          stepForwardInTheFlow()
        } catch (err) {
          setError(err as Error)
        } finally {
          if (!isInfoQuestion) {
            setIsLoading(false)
          }
        }
      }
    },
    [apiGateway, chatbotQuestionData, isLoading, setCurrentResponse, setIsCheckFinished, setIsLoading],
  )

  const getOverview = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get<OverviewResponse>('/v1/chatbot/overview')

      setCurrentResponse({ ...data, id: checkIfAddMoreSymptomId })
      setChatbotQuestionData([...chatbotQuestionData, { ...data, id: checkIfAddMoreSymptomId }])
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsOverview(false)
      setIsLoading(false)
    }
  }, [apiGateway, chatbotQuestionData, checkIfAddMoreSymptomId, setCurrentResponse, setIsLoading])

  const getReport = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get<MedicalEngineSymptomCheckReport | MedicalEngineIllnessCheckReport>(
        'v1/chatbot/report',
      )

      setCheckPhaseStep(4)
      setCurrentResponse(data)
      setChatbotQuestionData([...chatbotQuestionData, { ...data, id: 'REPORT' }])
      setIsReporting(true)
    } catch (err) {
      setError(err as Error)
    } finally {
      setShowReportActions(true)
      setIsReportMissing(false)
      setIsLoading(false)
    }
  }, [apiGateway, chatbotQuestionData, setCurrentResponse, setIsLoading, setIsReporting])

  const isNewQuestionNeeded = useMemo(
    () => checkId && (!chatbotQuestionData.length || !currentResponse),
    [checkId, currentResponse, chatbotQuestionData.length],
  )

  const isCheckNotFinalized = useMemo(
    () => !isLoading && !isOverview && !isReportMissing && !isCheckFinished,
    [isLoading, isOverview, isCheckFinished, isReportMissing],
  )

  const showReport = useMemo(
    () => showReportActions && !isMedicalResearchMode,
    [showReportActions, isMedicalResearchMode],
  )

  const sendConfirmAnswer = useCallback(
    () =>
      sendAnswer({
        questionId: (currentResponse as Question)?.id,
        answer: {
          id: (currentResponse as Question)?.options?.values[0]?.id,
        },
      }),
    [currentResponse, sendAnswer],
  )

  useEffect(() => {
    if (isNewQuestionNeeded && isCheckNotFinalized) {
      getQuestion()
    }
  }, [isNewQuestionNeeded, isCheckNotFinalized, getQuestion])

  useEffect(() => {
    if (isOverview && !isLoading) {
      getOverview()
    }
  }, [getOverview, isOverview, isLoading])

  useEffect(() => {
    if (isReportMissing && !isLoading) {
      getReport()
    }
  }, [getReport, isReportMissing, isLoading])

  useEffect(() => {
    determineBackgroundImageToUse(backgroundImage)
  }, [backgroundImage])

  useEffect(() => {
    setMainContainerWidth(width)
  }, [setMainContainerWidth, width])

  useEffect(() => {
    if (illnessCheckIcd10Code && currentResponseDetailedType === 'START_SCREEN') {
      setInitialIllness(illnessCheckIcd10Code)
      showTermsAndConditions(CHECK_TYPE_ILLNESS_CHECK)
    }
  }, [currentResponseDetailedType, illnessCheckIcd10Code, setInitialIllness, showTermsAndConditions])

  useEffect(() => {
    if (currentResponse && isWarningSign && !isWarningSignsPopupEnabled) {
      sendConfirmAnswer()
    }
  }, [isWarningSign, isWarningSignsPopupEnabled, sendConfirmAnswer, currentResponse])

  const shouldMoveFooterButtonsAboveKeyboard =
    parentEvents?.ui?.keyboardIsVisible &&
    currentResponseDetailedType === 'AGE' &&
    window.innerHeight - (parentEvents?.ui?.keyboardHeight ?? 0) > 300

  const { value: isScReportRedesignEnabled } = useStaticFeatureFlag('scReportRedesign', false)
  const { value: isIcReportRedesignEnabled } = useStaticFeatureFlag('icReportRedesign', false)
  const featureReportRedesignEnabled =
    (checkType === 'SYMPTOM_CHECK' && isScReportRedesignEnabled) ||
    (checkType === 'ILLNESS_CHECK' && isIcReportRedesignEnabled)

  const { value: isHcpPrototypeEnabled } = useStaticFeatureFlag('hcpPrototype', false)

  const mainStyles = [...[styles.mainContent], ...(isWarningSign ? [] : [styles.heightFix])].join(' ')

  const reportContainerStyle = featureReportRedesignEnabled ? styles.reportContainer : styles.reportContainerOld
  const containerStyles = useMemo(() => (showReport ? reportContainerStyle : mainStyles), [showReport, isWarningSign])

  const screenContent = useMemo(
    () => (
      <QuestionHandlerContainer
        featureReportRedesignEnabled={featureReportRedesignEnabled}
        isReporting={isReporting}
        shouldMoveFooterButtonsAboveKeyboard={shouldMoveFooterButtonsAboveKeyboard}
        keyboardHeight={parentEvents?.ui?.keyboardHeight ?? 0}
      >
        {checkType === '' && <StartScreen showTermsAndConditions={showTermsAndConditions} />}
        {checkType !== '' && (
          <Suspense fallback={<LoadingIndicator />}>
            <QuestionHandler
              checkId={checkId}
              currentResponse={currentResponse as ResponseType}
              chatbotQuestionData={chatbotQuestionData}
              isLoading={isLoading}
              startCheck={startCheck}
              stepForwardInTheFlow={stepForwardInTheFlow}
              stepBackInTheFlow={stepBackInTheFlow}
              sendAnswer={sendAnswer}
              setChatbotQuestionData={setChatbotQuestionData}
              setIsOverview={setIsOverview}
            />
          </Suspense>
        )}
      </QuestionHandlerContainer>
    ),
    [
      checkType,
      isMedicalResearchMode,
      showTermsAndConditions,
      currentResponse,
      chatbotQuestionData,
      isLoading,
      startCheck,
      stepBackInTheFlow,
      sendAnswer,
      parentEvents,
    ],
  )

  const medicalContent = useMemo(
    () => (
      <div style={{ width: '100%', height: '100%', flexGrow: 1 }}>
        <ContentLibrary
          contentLibraryItemId={contentLibraryItemId}
          onItemSelected={(id) => setContentLibraryItemId(id)}
        />
      </div>
    ),
    [contentLibraryItemId],
  )

  if (error) {
    throw error
  }

  if (!isUserOnline) {
    return (
      <Row style={{ height: '100%', width: '100%' }} justify="center" align="middle">
        <Row style={{ height: '100%', width: '50%' }}>
          <NoConnection />
        </Row>
      </Row>
    )
  }

  /**
   * handles clicking on the home icon in the sidebar and on the logo in the header
   *
   * @param onHomePageModalOpen A method opening the confirmation modal
   */
  const onHomeClick = (onHomePageModalOpen: () => void) => {
    if (isServicesOpen) {
      if (isMobileView) {
        setSideMenuOpen(false)
      }

      setServicesOpen(!isServicesOpen)
    }

    if (isContentLibraryOpen) {
      setContentLibraryOpen(!isContentLibraryOpen)
    }

    if (currentResponseDetailedType === 'START_SCREEN' && checkType !== 'HEALTH_CHECK') {
      setSideMenuOpen(false)
    } else {
      onHomePageModalOpen()
    }
  }

  /**
   * handles restarting the current check
   */
  const onRestartCheck = () => {
    if (continueCheck) {
      redirectAfterCheck(true)
    }

    setShowReportActions(false)
    // resetting every value of QuestionContext to their current value on terms and conditions page except checkType and mainContainerWidth
    setHideSkipButton(false)
    setCurrentResponse(legalDisclaimer)
    setNextButtonLogic(null)
    setNextButtonI18nKey('xund.general.confirm')
    setIsLoading(false)
    setIsNextButtonDisabled(false)
    setIsReporting(false)
    setIsCheckFinished(false)

    setCheckPhaseStep(0) // to reset step number when on terms and conditions page again
  }

  const redirectAfterCheck = (restartCheck: boolean) => {
    const queryStringParams = new URLSearchParams(queryString)

    queryStringParams.delete('checkId')

    if (restartCheck) {
      queryStringParams.append('restart', checkType)
    }

    // TODO use appLocation?
    // TODO rewrite this not using reload (just by setting state and/or context) after MainPage component is destructured and simplified
    window.location.search = queryStringParams.toString()
  }

  const containerShadow =
    isReporting && featureReportRedesignEnabled
      ? {}
      : { boxShadow: boxShadowHidden ? 'none' : '0px 16px 26px 0px rgba(0,0,0,0.05)' }

  const containerParams = {
    ...(isReporting && featureReportRedesignEnabled && !isContentLibraryOpen
      ? {
          lg: { span: 24 },
          md: { span: 24 },
          sm: { span: 24 },
        }
      : {
          lg: { span: 15 },
          md: { span: 19 },
          sm: { span: 19 },
        }),

    style: (currentResponseDetailedType !== 'START_SCREEN' && !isContentLibraryOpen
      ? {
          background: CSS_VARS.chatBackgroundColor,
          ...containerShadow,
        }
      : {
          minWidth: '100%',
          flexGrow: 1,
          minHeight: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }) as CSSProperties,
  }

  const scIcFlowContent = (
    <>
      <PageContainer
        isContentLibraryOpen={isContentLibraryOpen}
        isServicesOpen={isServicesOpen}
        isOnStartScreen={checkType === ''}
        isReport={showReport}
      >
        <CSSTransition
          in={currentResponseDetailedType === 'START_SCREEN' && !isContentLibraryOpen}
          timeout={500}
          classNames="innerWindowAnimation"
          appear
        >
          <Row justify="center" style={{ display: 'flex', flexDirection: isContentLibraryOpen ? 'column' : 'row' }}>
            {isContentLibraryOpen && <ContentLibraryTitle />}
            <Col
              ref={mainContainerRef}
              {...containerParams}
              data-testid="testShadow"
              className={currentResponseDetailedType !== 'START_SCREEN' && containerStyles}
            >
              {isContentLibraryOpen ? medicalContent : screenContent}

              {['CHECK_CLOSED', 'CHECK_ENDED'].includes(continueCheckStatus) && (
                <BaseModal
                  title={i18n('xund.general.continue.title')}
                  onCancel={() => redirectAfterCheck(false)}
                  onOk={() => redirectAfterCheck(true)}
                  okBtnTitle={i18n('xund.general.continue.restart')}
                  cancelBtnTitle={i18n('xund.general.cancel')}
                  contentStyle={{ paddingBottom: '50px' }}
                >
                  <ModalText title={i18n('xund.general.continue.content')} />
                </BaseModal>
              )}
              {featureReportRedesignEnabled ? (
                <FooterReportRedesign
                  showReportActions={showReport}
                  isCheckFinished={isCheckFinished}
                  chatbotQuestionData={chatbotQuestionData}
                  onBackClick={() => {
                    if (isLoading) {
                      return
                    }

                    if (contentLibraryItemId) {
                      setContentLibraryItemId('')
                    } else if (isContentLibraryOpen) {
                      setContentLibraryOpen(false)
                    } else {
                      stepBackInTheFlow()
                    }
                  }}
                  onSendAnswer={sendAnswer}
                  onRestartCheck={onRestartCheck}
                  continueCheck={continueCheck}
                />
              ) : (
                <Footer
                  showReportActions={showReport}
                  isCheckFinished={isCheckFinished}
                  chatbotQuestionData={chatbotQuestionData}
                  onBackClick={() => {
                    if (isLoading) {
                      return
                    }

                    if (contentLibraryItemId) {
                      setContentLibraryItemId('')
                    } else if (isContentLibraryOpen) {
                      setContentLibraryOpen(false)
                    } else {
                      stepBackInTheFlow()
                    }
                  }}
                  onSendAnswer={sendAnswer}
                  onRestartCheck={onRestartCheck}
                  continueCheck={continueCheck}
                />
              )}
            </Col>
          </Row>
        </CSSTransition>
        <PoweredByXund displayWave={currentResponseDetailedType === 'START_SCREEN'} />
      </PageContainer>
      {isServicesOpen && (
        <Suspense fallback={<LegacyLoadingIndicator />}>
          {isHcpPrototypeEnabled ? <HealthCareProvider onClose={() => setServicesOpen(false)} /> : <Services />}
        </Suspense>
      )}
    </>
  )

  return (
    <div id="background_image" className={styles.container}>
      <div id="innerContainer" className={styles.innerContainer}>
        {!(isServicesOpen && isMobileView) && (
          <Header
            onHomeClick={onHomeClick}
            checkType={checkType}
            scIcStep={checkPhaseStep}
            {...(((isServicesOpen && !isHcpPrototypeEnabled) || isContentLibraryOpen) && {
              onClose: () => {
                setServicesOpen(false)
                setContentLibraryOpen(false)
              },
            })}
          />
        )}

        <SideMenu
          onServicesOpen={() => {
            if (isMobileView) {
              setSideMenuOpen(false)
            }

            setServicesOpen(!isServicesOpen)
          }}
          onHomeClick={onHomeClick}
          onRestartCheck={onRestartCheck}
          showRestart={!['START_SCREEN', 'TERMS_AND_CONDITIONS'].includes(currentResponseDetailedType)}
        />

        {scIcFlowContent}
      </div>
    </div>
  )
}
