import React, { FC, useCallback, useEffect, useState } from 'react'
import { useApiGatewayContext } from '../../../context'
import { useI18n } from '../../../hooks'
import { Imprint } from '../../../models'
import { LoadingIndicator } from '../../../common/Indicators/LoadingIndicator'
import { BaseModal } from '../BaseModal/BaseModal'
import styles from './Impressum.module.less'
import { ScIcImpressumData } from './ScIcImpressumData'
import { ImpressumContactData } from './ImpressumContactData'

let scIcImpressumDataCache: Imprint | null

/**
 * @param props The props object
 * @param props.onCancel The method handling cancellation
 * @returns The Impressum component
 */
export const Impressum: FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const [scIcImpressumData, setScIcImpressumData] = useState<Imprint | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const { i18n } = useI18n()
  const { apiGateway } = useApiGatewayContext()

  const getImpressumData = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get(`v1/imprintResources`)
      scIcImpressumDataCache = data
      setScIcImpressumData(data)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway])

  useEffect(() => {
    if (!scIcImpressumDataCache) {
      getImpressumData()
    }

    if (scIcImpressumDataCache) {
      setScIcImpressumData(scIcImpressumDataCache)
    }
  }, [getImpressumData])

  if (error) {
    throw error
  }

  return (
    <BaseModal
      title={i18n('xund.imprint.title')}
      onCancel={onCancel}
      hasButtons={false}
      contentClassName={styles.impressum}
      isImpressumModal
    >
      {!isLoading && (
        <>
          {scIcImpressumData && <ImpressumContactData scIcImprint={scIcImpressumData} />}
          {scIcImpressumData && <ScIcImpressumData imprint={scIcImpressumData} />}
        </>
      )}
      {isLoading && <LoadingIndicator delay={200} inline />}
    </BaseModal>
  )
}
