import React, { FC, useState } from 'react'
import { Container, Header, PageDescription, Wrapper } from './HealthCareProvider.styled'

import { Button, ButtonWrapper } from './components/Button.styled'
import { ReactComponent as IconBack } from '../../assets/icons/arrow-back.svg'

import { PoweredByXund } from '../General/PoweredByXund/PoweredByXund'

import { useSpecializations } from './hooks/useSpecializations'

import { FilterList } from './components/FilterList/FilterList'
import { Location, ResultListProp, SelectProp, ServicesType } from './types'
import { ResultList } from './components/ResultList/ResultList'

import { useProviderSearch } from './hooks/useProviderSearch'
import { OPENING_HOURS_OPTIONS, SERVICE_OPTION_ALL, SERVICE_OPTIONS } from './constants'

export const HealthCareProvider: FC<{
  children?: React.ReactNode
  onClose: () => void
}> = (props) => {
  const { search: providerSearch, results, isLoading, googleIsLoaded } = useProviderSearch()

  const { specializations } = useSpecializations()

  const [currentLocation, setCurrentLocation] = useState<Location>({
    coordinates: { lat: 48.2076469, lng: 16.3668079 },
  })
  const [currentSpecializations, setCurrentSpecializations] = useState<SelectProp[]>()
  const [currentServices, setCurrentServices] = useState<SelectProp[]>([{ ...SERVICE_OPTION_ALL }])
  const [currentOpening, setCurrentOpening] = useState<SelectProp[]>([OPENING_HOURS_OPTIONS[0]])

  const handleSubmit = async () => {
    providerSearch({
      location: currentLocation,
      serviceTypes: (currentServices.find((s) => s.name === 'all')
        ? [...SERVICE_OPTIONS.filter((s) => s.name !== 'all')]
        : currentServices) as ServicesType[],
      specializations:
        (currentServices.find((s) => s.name === 'all' || s.name === 'doctor') &&
          currentSpecializations?.map((s) => s.name)) ||
        [],
    })
  }

  return (
    <Wrapper>
      <Container>
        <ButtonWrapper>
          <Button icon={<IconBack />} iconStrokeColor={'black'} transparent onClick={props.onClose}>
            Back to report
          </Button>
        </ButtonWrapper>

        <Header>Healthcare providers</Header>
        <PageDescription>
          Find trusted doctors, pharmacies, and hospitals to support your health journey. Explore healthcare options and
          take the next step in managing your health.
        </PageDescription>

        <FilterList
          specializations={specializations}
          onSubmit={handleSubmit}
          onLocationChange={setCurrentLocation}
          onSpecializationChange={setCurrentSpecializations}
          onServicesChange={setCurrentServices}
          onOpeningChange={setCurrentOpening}
          isLoading={isLoading || !googleIsLoaded}
        />

        <ResultList results={results as ResultListProp[]} location={currentLocation} opening={currentOpening[0]} />

        <PoweredByXund displayWave={false} />
      </Container>
    </Wrapper>
  )
}
