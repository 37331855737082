import React, { FC } from 'react'
import { Select as StyledSelect, Container, Prefix } from './Select.styled'
import { DownOutlined } from '@ant-design/icons'
import { CustomSelectProps } from '../types'

export const Select: FC<CustomSelectProps> = (props) => {
  return (
    <Container>
      {props.prefix && <Prefix>{props.prefix}</Prefix>}
      <StyledSelect
        allowClear={false}
        bordered={false}
        placeholder="Select Item..."
        maxTagCount="responsive"
        style={{ width: '100%' }}
        {...props}
      />
      <DownOutlined />
    </Container>
  )
}
