import styled from '@emotion/styled'
import { Input as AntInput } from 'antd'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

export const Input = styled(AntInput)(`

  input.ant-input{
    font-family: ${CSS_VARS.fontFamily}!important;
  }

  // avoid zoom when focusing inputs on iphones:
  @media screen and (max-width: 576px) {
    input.ant-input {
        font-size: 16px!important;
    }
  }
  `)
