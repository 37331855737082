// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#HcjzAE9KTSIsjz5x {\n  width: 784px;\n  max-height: 95%;\n  overflow: scroll;\n  padding: 50px 70px 10px 70px;\n  text-align: center;\n  box-shadow: 3px 13px 54px 0 rgba(0, 65, 122, 0.18);\n}\n#HcjzAE9KTSIsjz5x h1 {\n  margin: 0 auto 28px auto;\n}\n#HcjzAE9KTSIsjz5x .H1VPfuIBSjUKMhlT {\n  height: 30px;\n  min-height: 30px;\n  border-radius: 15px;\n  padding: 0 20px;\n}\n#HcjzAE9KTSIsjz5x .RLmuOEduBqgxTvnZ {\n  width: 100%;\n}\n#HcjzAE9KTSIsjz5x .f2jnxYCn0AqJ5Z2F {\n  margin-bottom: 30px;\n}\n@media screen and (max-width: 576px) {\n  #HcjzAE9KTSIsjz5x {\n    padding: 25px 40px 10px 40px;\n  }\n  #HcjzAE9KTSIsjz5x .H1VPfuIBSjUKMhlT {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Modals/BaseModal/BaseModal.module.less"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,eAAA;EACA,gBAAA;EACA,4BAAA;EACA,kBAAA;EACA,kDAAA;AADJ;AALA;EASQ,wBAAA;AADR;AARA;EAaQ,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAFR;AAdA;EAoBQ,WAAA;AAHR;AAjBA;EAwBQ,mBAAA;AAJR;AAQA;EACI;IACI,4BAAA;EANN;EAKE;IAIQ,WAAA;EANV;AACF","sourcesContent":["@import '../../../variables.less';\n\n#baseModal {\n    width: 784px;\n    max-height: 95%;\n    overflow: scroll;\n    padding: 50px 70px 10px 70px;\n    text-align: center;\n    box-shadow: 3px 13px 54px 0 rgba(0, 65, 122, 0.18);\n\n    h1 {\n        margin: 0 auto 28px auto;\n    }\n\n    .button {\n        height: 30px;\n        min-height: 30px;\n        border-radius: 15px;\n        padding: 0 20px;\n    }\n\n    .content {\n        width: 100%;\n    }\n\n    .bottomMargin {\n        margin-bottom: 30px;\n    }\n}\n\n@media screen and (max-width: @mobile-max-width) {\n    #baseModal {\n        padding: 25px 40px 10px 40px;\n\n        .button {\n            width: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseModal": "HcjzAE9KTSIsjz5x",
	"button": "H1VPfuIBSjUKMhlT",
	"content": "RLmuOEduBqgxTvnZ",
	"bottomMargin": "f2jnxYCn0AqJ5Z2F"
};
export default ___CSS_LOADER_EXPORT___;
