// Card.tsx
import React, { ReactNode, useState } from 'react'
import {
  Card as StyledCard,
  CardItem as StyledCardItem,
  CardHeader as StyledCardHeader,
  CardAddress as StyledCardAddress,
  CardDetails as StyledCardDetails,
  CardOpeningHours as StyledCardOpeningHours,
} from './Card.styled'
import { ReactComponent as IconArrow } from '../../../assets/icons/arrowDown.svg'

import { Button } from 'antd'

interface CardProps {
  children: ReactNode
  grid?: string
}
interface CardItemProps {
  children: ReactNode
  justify?: string
  disable?: boolean
}
interface CardHeaderProps {
  children: ReactNode
  icon?: ReactNode
}
interface CardDetailsProps {
  children: ReactNode
  icon?: ReactNode
}
interface CardAddressProps {
  children: ReactNode
}
interface CardOpeningHoursProps {
  today: string
  week: string[]
}

const Card: React.FC<CardProps> & {
  Item: typeof CardItem
  Header: typeof CardHeader
  Address: typeof CardAddress
  Details: typeof CardDetails
  OpeningHours: typeof CardOpeningHours
} = (props) => {
  return <StyledCard {...props}>{props.children}</StyledCard>
}

const CardHeader: React.FC<CardHeaderProps> = (props) => {
  return (
    <StyledCardHeader {...props}>
      <>
        {props.icon || <></>}
        <div>{props.children}</div>
      </>
    </StyledCardHeader>
  )
}

const CardItem: React.FC<CardItemProps> = (props) => {
  return <StyledCardItem {...props}>{props.children}</StyledCardItem>
}

const CardAddress: React.FC<CardAddressProps> = (props) => {
  return <StyledCardAddress {...props}>{props.children}</StyledCardAddress>
}

const CardDetails: React.FC<CardDetailsProps> = (props) => (
  <StyledCardDetails {...props}>
    <>
      {props.icon || <></>}
      <div>{props.children}</div>
    </>
  </StyledCardDetails>
)
const CardOpeningHours: React.FC<CardOpeningHoursProps> = (props) => {
  const [open, setOpen] = useState(false)
  return (
    <StyledCardOpeningHours {...props} open={open}>
      <>
        {open ? (
          <p>
            {props.week.map((day) => (
              <span key={day}>{day}</span>
            ))}
          </p>
        ) : (
          <p>{props.today}</p>
        )}
        <Button
          onClick={() => setOpen((prev) => !prev)}
          icon={<IconArrow />}
          type="text"
          size="small"
          shape="circle"
        ></Button>
      </>
    </StyledCardOpeningHours>
  )
}

Card.Item = CardItem
Card.Header = CardHeader
Card.Address = CardAddress
Card.Details = CardDetails
Card.OpeningHours = CardOpeningHours

export { Card }
