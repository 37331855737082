import { Coordinates, ResultListProp, SelectProp, Services } from '../../types'
import React, { useEffect, useState } from 'react'
import { Card } from '../Card'
import { Select } from '../Select'

import { Button } from '../Button.styled'
import { Location } from '../../types'
import { EmptyPlaceholder, Pagination, Results } from './ResultList.styled'
import { Flex } from '../../../Flex'

import { ReactComponent as IconDoctor } from '../../../../assets/icons/doctor-icon.svg'
import { ReactComponent as IconPharmacy } from '../../../../assets/icons/pharmacy-icon.svg'
import { ReactComponent as IconHospital } from '../../../../assets/icons/hospital-icon.svg'
import { ReactComponent as IconWatch } from '../../../../assets/icons/watch-icon.svg'
import { ReactComponent as IconPhone } from '../../../../assets/icons/phone-icon.svg'
import { ReactComponent as IconMap } from '../../../../assets/icons/map-icon.svg'
import { ReactComponent as IconMapEmpty } from '../../../../assets/icons/map-icon-empty.svg'
import { formatOpeningHours } from '../../utils/getOpeningHours'
import { SORT_OPTIONS } from '../../constants'

const PAGE_SIZE = 6 as const

export const ResultList = ({
  results,
  location,
  opening,
}: {
  results: ResultListProp[]
  location?: Location
  opening: SelectProp
}) => {
  const [variant, setVariant] = useState<'empty' | 'initial' | 'valid'>('initial')
  const [pagedResults, setPagedResults] = useState(results?.slice(1, 1 + PAGE_SIZE) || [])
  const [filteredResults, setFilteredResults] = useState<ResultListProp[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortOption, setSortOption] = useState<SelectProp>(SORT_OPTIONS[0])

  const pageOnChange = (page: number) => {
    setCurrentPage(page)
  }

  const getOpenPlaces = (timeOfDay: SelectProp, data: ResultListProp[]) => {
    const now = new Date()
    const currentDay = now.getDay()

    if (!data || data.length === 0) return

    if (timeOfDay.name === 'showAll') {
      return data
    }

    return data.filter((item: ResultListProp) => {
      if (item.periods?.[0]?.open?.time === '0000' && !item.periods?.[0]?.close) {
        return true // Open 24/7
      }

      return item.periods?.some((period) => {
        if (period.open.day === currentDay) {
          const openTime = parseInt(period.open.time, 10)
          const closeTime = period.close ? parseInt(period.close.time, 10) : 2400

          return timeOfDay.name === 'noon'
            ? openTime < 1200 && (!closeTime || closeTime > 1200)
            : openTime <= 1200 && (!closeTime || closeTime <= 2400)
        }
        return false
      })
    })
  }

  const trimResults = React.useCallback(() => {
    if (!results) {
      return []
    }
    const getDist = (a: Coordinates, b: Coordinates) => {
      return Math.sqrt(Math.pow(a.lat - b.lat, 2) + Math.pow(a.lng - b.lng, 2))
    }

    const sortResults = (array: ResultListProp[]) => {
      switch (sortOption.name) {
        case 'az':
          return array.sort((a, b) => a.name.localeCompare(b.name))
        case 'za':
          return array.sort((a, b) => b.name.localeCompare(a.name))
        default:
          return location
            ? array.sort(
                (a, b) => getDist(location.coordinates, a.location) - getDist(location.coordinates, b.location),
              )
            : array
      }
    }

    const cursor = (currentPage - 1) * PAGE_SIZE

    const filtered = getOpenPlaces(opening, results)
    if (filtered) {
      setFilteredResults(filtered)

      setPagedResults(sortResults(filtered).slice(cursor, cursor + PAGE_SIZE) || [])
    }
  }, [currentPage, results, sortOption.name, location, opening])

  useEffect(() => {
    trimResults()
  }, [trimResults, currentPage, sortOption])

  const placeholder = (
    <EmptyPlaceholder>
      {results && results.length === 0 ? (
        <>
          <IconMapEmpty />
          <h2>No Matching Results Found</h2>
          <p>
            We couldn’t find any services that match your search criteria. Try adjusting your filters or checking your
            input for any errors.
          </p>
        </>
      ) : (
        <>
          <IconMap />
          <h2>Help us find the right services for you</h2>
          <p>
            Enter your location or enable location access on your device to help us find the most relevant services for
            you.
          </p>
        </>
      )}
    </EmptyPlaceholder>
  )

  useEffect(() => {
    if (!results) {
      setVariant('initial')
    } else if (results?.length === 0) {
      setVariant('empty')
    } else {
      setVariant('valid')
    }
    //setPagedResult(results?.slice(0, PAGE_SIZE) || [])
    trimResults()
  }, [results, trimResults])

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const medIcon = (service: Services) => {
    switch (service) {
      case 'pharmacy':
        return <IconPharmacy />
      case 'hospital':
        return <IconHospital />
      default:
        return <IconDoctor />
    }
  }

  return (
    <Results variant={variant}>
      {!results || results?.length === 0 ? (
        placeholder
      ) : (
        <>
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
            <h2>Best findings for you</h2>

            <Select
              options={[...SORT_OPTIONS]}
              prefix="Sort by"
              value={sortOption}
              onSelect={(_: string, option: SelectProp) => {
                setSortOption(option)
              }}
              placeholder="Select..."
            />
          </Flex>
          <div className="hpc-result-wrapper">
            {pagedResults.map((result: ResultListProp) => {
              const mapUrl = isMobile
                ? `geo:${result.location.lat},${result.location.lng}`
                : result.place_id
                ? `https://www.google.com/maps/place/?q=place_id:${result.place_id}`
                : `https://www.google.com/maps?q=${result.location.lat},${result.location.lng}`

              const openingHours: { today: string; week: string[] } | null = result.periods
                ? formatOpeningHours(result.periods)
                : null

              return (
                <Card key={result.id}>
                  <Card.Header icon={medIcon(result.service as Services)}>
                    <h3>{result.name} </h3>
                    <p>{result.specialization}</p>
                  </Card.Header>
                  <Card.Address>{result.address}</Card.Address>
                  <Flex gap="8px" flexDirection="column">
                    <Card.Details icon={<IconWatch />}>
                      {openingHours && (
                        <Card.OpeningHours
                          today={openingHours.today || '-'}
                          week={openingHours.week || ['-']}
                        ></Card.OpeningHours>
                      )}
                    </Card.Details>
                    <Card.Details icon={<IconPhone />}>{result.phone || '-'}</Card.Details>
                  </Flex>
                  <Button type="primary" href={mapUrl} target="_blank">
                    View on map
                  </Button>
                </Card>
              )
            })}
          </div>
          <Pagination
            showQuickJumper={false}
            showSizeChanger={false}
            defaultCurrent={currentPage}
            onChange={pageOnChange}
            pageSize={6}
            total={filteredResults.length}
          />
        </>
      )}
    </Results>
  )
}
