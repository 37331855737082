import { useEffect, useState } from 'react'
import { useAuthToken } from '../../../hooks/useAuthToken'
import { Specialization } from '../../../models'

export const useSpecializations = () => {
  const { token } = useAuthToken()
  const [specializations, setSpecializations] = useState<Specialization[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<unknown>(null)

  useEffect(() => {
    const fetchSpecializations = async () => {
      if (!token) return

      setIsLoading(true)
      try {
        const response = await fetch(
          `${window.xundEnvironment.MEDICAL_SERVICES_BASE_URL}/services/specializations/AT`,
          {
            headers: {
              language: 'en', // since IQVIA provides specialties in english
              authorization: `Bearer ${token}`,
            },
          },
        )
        const data = await response.json()
        setSpecializations(data)
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchSpecializations()
  }, [token])

  return { specializations, isLoading, error }
}
