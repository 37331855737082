import { useEffect, useRef, useState } from 'react'
import { GoogleService, PlaceDetailsResult, PlaceResult } from '../types/googlePlacesTypes'
declare global {
  interface Window {
    google: any
  }
}
const GOOGLE_API_KEY = window.xundEnvironment?.GOOGLE_API_KEY

export function useGooglePlacesApi() {
  const googleScriptLoaded = useRef(false)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const loadGoogleMapsScript = (callback: () => void) => {
      if (window.google) {
        callback()
        return
      }
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`
      script.async = true
      script.onload = callback
      document.body.appendChild(script)
    }

    loadGoogleMapsScript(() => {
      googleScriptLoaded.current = true
      setIsLoaded(true)
    })
  }, [])

  const getSuggestions = (inputValue: string) => {
    return new Promise((resolve, reject) => {
      if (!window.google || !window.google.maps) {
        reject('Google Maps API not loaded')
        return
      }
      const autocompleteService = new window.google.maps.places.AutocompleteService()
      autocompleteService.getPlacePredictions({ input: inputValue }, (predictions: unknown[], status: unknown) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          resolve(predictions)
        } else {
          reject(status)
        }
      })
    })
  }

  const fetchPlaceDetails = (placeId: string) => {
    return new Promise((resolve, reject) => {
      if (!window.google || !window.google.maps) {
        reject('Google Maps API not loaded')
        return
      }
      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'))
      placesService.getDetails(
        { placeId },
        (
          place: { geometry: { location: { lat: () => void; lng: () => void } }; address_components: never[] },
          status: unknown,
        ) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
            const coordinates = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }
            const addressComponents = place.address_components || []
            const countryComponent = (addressComponents as Array<{ types: string[]; short_name: string }>).find(
              (component) => component.types.includes('country'),
            )
            const country = countryComponent ? countryComponent.short_name : 'Unknown'

            resolve({ coordinates, country })
          } else {
            reject(status)
          }
        },
      )
    })
  }

  const nearBySearch = (request: {
    location: { lat: number; lng: number }
    radius: number
    type: 'doctor' | 'hospital' | 'pharmacy'
    keyword?: string
  }) => {
    return new Promise((resolve, reject) => {
      if (!window.google || !window.google.maps) {
        reject('Google Maps API not loaded')
        return
      }
      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'))

      placesService.nearbySearch(request, async (res: PlaceResult[], status: unknown) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const detailedResults = res.map(async (place: PlaceResult) => ({
            ...place,
            ...((await getPlaceDetails(placesService, place.place_id, place)) as PlaceResult),
            ...{ place_id: place.place_id },
          }))

          resolve(await Promise.all(detailedResults))
        } else {
          // eslint-disable-next-line no-console
          console.error('PlacesService failed:', status)
          reject(status)
        }
      })
    })
  }

  const getPlaceDetails = async (service: GoogleService, placeId: string, place: PlaceResult) => {
    return new Promise((resolve) => {
      const request = {
        placeId,
        fields: ['name', 'vicinity', 'formatted_phone_number', 'opening_hours'],
        language: 'de',
      }

      service.getDetails(request, (details: PlaceDetailsResult | null, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve({ ...details, openingHours: details?.opening_hours })
        }
      })
    })
  }

  return { isLoaded, getSuggestions, fetchPlaceDetails, nearBySearch }
}
