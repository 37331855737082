type OpeningHours = {
  close?: { day: number; time: string; hours: number; minutes: number }
  open: { day: number; time: string; hours: number; minutes: number }
}

export type WeekHours = OpeningHours[]

const weekDays: string[] = ['Sunday', 'Monday', 'Thuesday', 'Wednesday', 'Tuesday', 'Friday', 'Saturday']

function formatTime(time: string): string {
  return `${time.slice(0, 2)}:${time.slice(2)}`
}

function isAllDay(opening: OpeningHours): boolean {
  return opening.open.time === '0000' && (!opening.close || opening.close.time === '2400')
}

export const formatOpeningHours = (weekHours: WeekHours): { week: string[]; today: string } => {
  const result: string[] = new Array(7).fill('Closed')

  if (weekHours.length === 1 && isAllDay(weekHours[0]) && !weekHours[0].close) {
    weekDays.forEach((_: string, index: number) => {
      result[index] = 'All day'
    })
  } else {
    weekHours.forEach(({ open, close }) => {
      result[open.day] = `${formatTime(open.time)}–${close && formatTime(close.time)}`
    })
  }
  const today = new Date().getDay()
  const week = result.map((hours, index) => `${weekDays[index]}: ${hours}`)

  return { week, today: week[today] }
}
