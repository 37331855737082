// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MMd3EbmuMrWoZWTU {\n  width: 17px;\n  height: 17px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  box-sizing: border-box;\n  cursor: pointer;\n}\n.MMd3EbmuMrWoZWTU div {\n  width: 17px;\n  height: 1.5px;\n}\n.bQlErEdGQ8sCOaRh {\n  width: 17px;\n  height: 17px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/SideMenuToggle/SideMenuToggle.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;AACF;AAEA;EACE,WAAA;EACA,aAAA;AAAF;AAGA;EACE,WAAA;EACA,YAAA;AADF","sourcesContent":[".SideMenuToggle {\n  width: 17px;\n  height: 17px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  box-sizing: border-box;\n  cursor: pointer;\n}\n\n.SideMenuToggle div {\n  width: 17px;\n  height: 1.5px;\n}\n\n.icon {\n  width: 17px;\n  height: 17px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SideMenuToggle": "MMd3EbmuMrWoZWTU",
	"icon": "bQlErEdGQ8sCOaRh"
};
export default ___CSS_LOADER_EXPORT___;
