import styled from '@emotion/styled'

export const Flex = styled.div<{
  justifyContent?: 'start' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'end'
  alignItems?: 'start' | 'center' | 'end' | 'stretch'
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  width?: string
  gap?: string
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse'
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? 'start'};
  align-items: ${(props) => props.alignItems ?? 'start'};
  flex-direction: ${(props) => props.flexDirection ?? 'row'};
  width: ${(props) => props.width ?? 'initial'};
  gap: ${(props) => props.gap ?? 'initial'};
  flex-wrap: ${(props) => props.flexWrap ?? 'initial'};
`
